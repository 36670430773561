import Vimeo from "@u-wave/react-vimeo";
import React from "react";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import styles from "./TrailerDescription.module.css";

type Props = {
  trailerUrl: string;
  description: string;
  showPayButton: boolean;
  handleClick(): Promise<void>;
};

const TrailerDescription = (props: Props) => {
  return (
    <div className={styles.descriptionContainer}>
      {props.trailerUrl && (
        <div className={styles.videoWrapper}>
          {/* tslint:disable-next-line: no-unsafe-any */}
          <Vimeo
            responsive
            video={props.trailerUrl}
            className={styles.vimeoPlayer}
          />
        </div>
      )}
      <div className={styles.textWrapper}>
        <Typography type="text">
          {/* tslint:disable-next-line */}
          <span
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          />
        </Typography>
        {props.showPayButton ? (
          <div className={styles.payButton}>
            <Button handleClick={props.handleClick}>КУПИТЬ</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TrailerDescription;
