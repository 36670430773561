import { Link } from "gatsby";
import React from "react";
import { connect } from "react-redux";
import Button from "src/components/Button";
import DisplayRating from "src/components/DisplayRating";
import SectionHeader from "src/components/SectionHeader";
import Video from "src/components/Video";
import { User, Video as VideoType, VideoRating } from "src/types";
import styles from "./Videos.module.css";

interface ConnectStateProps {
  user?: User;
  ratings: { [x: string]: VideoRating };
}

interface Props extends ConnectStateProps {
  videos: VideoType[];
  withRating?: boolean;
  playlistId: string;
  handleVideoClick(): Promise<void>;
}

class Videos extends React.Component<Props> {
  public render() {
    const { props } = this;
    if (!props.videos?.length) {
      return null;
    }

    return (
      <>
        <section className={styles.sectionWrapper}>
          <SectionHeader header="Видео" />
          <div className={styles.videosWrapper}>
            {props.videos.map(this.renderVideo)}
          </div>
        </section>
      </>
    );
  }

  private renderVideo = (video, i) => {
    const { props } = this;
    const rating = props.ratings[video._id];

    const shouldVoteForVideo = props.withRating && !rating;
    const shouldRenderRating = props.withRating && rating;

    return (
      <div key={i} className={styles.videoWrapper}>
        <Video
          video={video}
          handleEmptyLinkClick={props.handleVideoClick}
          rating={rating}
          withRating={props.withRating}
          playlistId={props.playlistId}
        />
        {shouldVoteForVideo ? (
          <Link
            className={styles.linkRating}
            to="/set-rating"
            state={{
              modal: true,
              playlistId: this.props.playlistId,
              userId: props.user?._id,
              video: video,
              blackpage: true,
              closeTo:
                typeof window !== `undefined` ? window.location.pathname : "/",
            }}
          >
            <Button className={styles.voteButton}> проголосовать </Button>
          </Link>
        ) : null}
        {shouldRenderRating ? <DisplayRating rating={rating} /> : null}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  ratings: state.ratings.ratings,
});

export default connect<ConnectStateProps>(mapStateToProps)(Videos);
