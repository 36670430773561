import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import RecommendedVideos from "src/components/RecommendedVideos/RecommendedVideos";
import { User, Video } from "src/types";

type ConnectStateProps = {
  user?: User;
};

interface Props extends ConnectStateProps {
  playlistId: string;
}

type State = {
  videos?: Video[];
};

class AddVideos extends React.Component<Props, State> {
  public state: State = {
    videos: null,
  };

  public async componentDidMount() {
    try {
      await this.getAddVideos();
    } catch (error) {
      // tslint:disable-next-line: no-unsafe-any
      console.error(error && error.message);
    }
  }

  public async componentDidUpdate(prevProps: Props) {
    if (this.props.user?._id && prevProps?.user?._id !== this.props.user?._id) {
      await this.getAddVideos();
    }
  }

  public render() {
    if (!this.state.videos?.length) {
      return null;
    }

    return <RecommendedVideos videos={this.state.videos} hideDisableIcon />;
  }

  private getAddVideos = async () => {
    const res = await Api.getPlaylistAddVideos(this.props.playlistId);
    this.setState({ videos: res.data });
  };
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
});

export default connect<ConnectStateProps>(mapStateToProps)(AddVideos);
