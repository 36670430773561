import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import Seo from "src/components/Seo";
import { actions as ratingsActions } from "src/store/Rating";
import { actions as userActions } from "src/store/User";
import { PageProps, Playlist, User } from "src/types";
import PlaylistComponent from "./Playlist";

interface ConnectStateProps {
  user?: User;
}
interface ConnectDispatchProps {
  getRatings: typeof ratingsActions.getRatings;
  getUser(): typeof userActions.getUser;
}

interface Props extends ConnectStateProps, ConnectDispatchProps, PageProps {
  data?: Playlist[];
}
interface State {
  playlist?: Playlist;
}

class PlaylistContainer extends React.Component<Props, State> {
  public state: State = {
    playlist: null,
  };

  public async componentDidMount() {
    try {
      await this.getPlaylist();
      this.props.getUser();

      if (this.state.playlist.withRating) {
        this.props.getRatings(this.state.playlist._id);
      }
    } catch (error) {
      // tslint:disable-next-line: no-unsafe-any
      console.error(error && error.message);
    }
  }

  public render() {
    const plTrName = this.props.location.pathname.replace(/\/playlist\//, "");
    if (!plTrName) return null;

    const staticData: Playlist = this.props.data[plTrName];
    const pl: Playlist = this.state.playlist || staticData;

    if (!pl) {
      return null;
    }
    return (
      <>
        <Seo
          pageTitle={pl.name}
          pageDescription={(pl.description || "").replace(/<(.|\n)*?>/g, "")}
        />
        <PlaylistComponent
          data={pl}
          handleVideoClick={this.handleVideoClick}
          handlePayButtonClick={this.handlePayButtonClick}
        />
      </>
    );
  }

  private handleVideoClick = async () => {
    if (!this.props.user?.email) {
      await navigate("/register", {
        state: {
          modal: true,
          noScroll: true,
          closeTo: this.props.location.pathname,
        },
      });
    }
  };

  private handlePayButtonClick = async () => {
    if (!this.props.user?.email) {
      await navigate("/register", {
        state: {
          modal: true,
          noScroll: true,
          closeTo: this.props.location.pathname,
        },
      });
      return;
    }
    if (this.state.playlist?.good) {
      await navigate("/pay", { state: { good: this.state.playlist.good } });
    }
  };

  private getPlaylist = async () => {
    const res = await Api.getPlaylist(this.props["*"]);
    this.setState({ playlist: res.data });
  };
}

const mapActionsToProps = {
  getUser: userActions.getUser,
  getRatings: ratingsActions.getRatings,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(PlaylistContainer);
