import * as React from "react";
import AddVideos from "src/components/AddVideos";
import MirazPartners from "src/components/MIrazPartners";
import PageHeader from "src/components/PageHeader";
import Teachers from "src/components/Persons";
import TimePrice from "src/components/TimePrice";
import TrailerDescription from "src/components/TrailerDescription";
import Videos from "src/components/Videos";
import mirazLogo from "src/images/miraz_logo_big.svg";
import { Playlist as PlaylistType } from "src/types";

interface Props {
  data: PlaylistType;
  handleVideoClick(): Promise<void>;
  handlePayButtonClick(): Promise<void>;
}

const Playlist: React.SFC<Props> = ({
  data,
  handleVideoClick,
  handlePayButtonClick,
}) => {
  return (
    <div>
      <PageHeader text={data.name} />
      <TimePrice
        duration={data.duration}
        good={data.good}
        showPrice={!!data.good && !!data.good.price && !data.payedByUser}
      />
      <TrailerDescription
        showPayButton={!!data.good && !!data.good.price && !data.payedByUser}
        handleClick={handlePayButtonClick}
        trailerUrl={data.trailer}
        description={data.description}
      />
      <Videos
        videos={data.videos}
        handleVideoClick={handleVideoClick}
        withRating={data.withRating}
        playlistId={data._id}
      />
      {data.isMiraz ? (
        <div
          style={{
            margin: "88px auto",
          }}
        >
          <img
            src={mirazLogo}
            style={{
              display: "block",
              margin: "auto",
            }}
          />
        </div>
      ) : null}
      <Teachers teachers={data.teachers} />
      {data.additionalVideosIds?.length ? (
        <AddVideos playlistId={data._id} />
      ) : null}
      {data.isMiraz ? <MirazPartners /> : null}
    </div>
  );
};

export default Playlist;
