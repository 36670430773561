import Img from "gatsby-image";
import * as React from "react";
import SectionHeader from "src/components/SectionHeader";

import { graphql, useStaticQuery } from "gatsby";
import styles from "./Partners.module.css";

const Partners = () => {
  const assetsPhotos = useStaticQuery(query);
  return (
    <div className={styles.container}>
      <div>
        <SectionHeader header="Партнеры" />
        <div className={styles.imgsRowWrapper}>
          {mapPics(assetsPhotos.allFile.edges)}
        </div>
      </div>
    </div>
  );
};

const mapPics = (data: {}[]) =>
  // @ts-ignore
  data.map((curr) => ImgWrapper(curr.node.childImageSharp));

const ImgWrapper = (props) => <Img {...props} />;

const query = graphql`
  query AssetsPhotos {
    allFile(filter: { extension: { in: ["png"] }, dir: { regex: "/miraz/" } }) {
      edges {
        node {
          childImageSharp {
            id
            fluid(maxWidth: 190, maxHeight: 90, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Partners;
