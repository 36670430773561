import React from "react";
import Typography from "src/components/Typography";
import styles from "./TimePrice.module.css";

type Props = {
  duration?: number;
  good?: { price: number };
  showPrice?: boolean;
};

const TimePrice = (props: Props) => {
  if (!props.duration && !props.good?.price) {
    return null;
  }

  return (
    <div className={styles.timePriceContainer}>
      {props.duration ? (
        <Typography type="h3">{`${props.duration / 3600} часов`}</Typography>
      ) : (
        <span />
      )}
      {props.showPrice ? (
        <Typography type="h3">{`${props.good.price / 100} Р`}</Typography>
      ) : null}
    </div>
  );
};

export default TimePrice;
